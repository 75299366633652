// ----------------------------------- BTNS

.btns {
    text-align: center;
    @extend .clearfix;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: darken($h1-color, 10%);


    li {
        display: inline-block;
        padding: 0;
        background: none;
        position: relative;
        width: calc(100% / 3);
        max-height: 277px;
        float: left;
        transition: .3s;
        overflow: hidden;

        @media only screen and (max-width: 990px) {
            width: 100%;
            float: none;
            display: block;
            height: 250px;
        }

        a {
            @include box(100%);
            display: block;
            transition: .3s;


            &:before {
                @include before(100%, 50%);
                bottom: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
                z-index: 101;
                opacity: .8;
                transition: .3s;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s;
            filter: grayscale(100%);
            height: 277px;
            object-position: top;
        }

        span {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            color: #fff;
            width: 100%;
            z-index: 102;
            padding: 12px 0;
            text-transform: uppercase;
            font-size: 2vw;
            font-weight: 700;

            @media only screen and (max-width: 990px) {
                font-size: 22px;
            }
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        &:nth-of-type(1) span {
            background: #6f7271;
            color: #fff;
        }

        &:nth-of-type(2) span {
            background: #8A2432;
            color: #fff;
        }

        &:nth-of-type(3) span {
            background: #9ea1a2;
            color: #000;
        }
    }

    @media only screen and (min-width: 990px) {

        &:hover li:not(:hover) {
            transform: scale(.9);
            opacity: .3;
        }
    }
}