// ----------------------------------- WELCOME SEC 

#welcome-sec {
    padding: 100px 0 80px;
    background: #8A2432;
    position: relative;

    @media only screen and (max-width: 990px) {
        padding: 50px 0 80px;
    }

    &:before {
        @include before(calc(100% / 3), 100%);
        background: #000;
        left: 0;
        top: 0;

        @media only screen and (max-width: 1600px) {
            @include before(calc(100% / 4), 100%);
        }

        @media only screen and (max-width: 990px) {
            display: none;
        }
    }

    .wrapper {
        width: 1250px;
        max-width: 95%;
    }

    .img-cont,
    .text-cont {
        display: inline-block;
        vertical-align: top;
    }

    .img-cont {
        width: 620px;
        margin: 15px 50px 0 0;
        float: left;

        @media only screen and (max-width: 1600px) {
            width: 520px;
        }

        @media only screen and (max-width: 990px) {
            float: none;
            display: block;
            max-width: 95%;
            margin: 0 auto;
        }

        img {
            width: 100%;
            filter: grayscale(100%);
        }
    }

    .text-cont {
        width: calc(100% - 620px - 50px);
        margin: 0;

        @media only screen and (max-width: 1600px) {
            width: calc(100% - 520px - 50px);
        }

        @media only screen and (max-width: 990px) {
            display: block;
            width: 520px;
            max-width: 95%;
            margin: 0 auto 30px;
        }

        h2 {
            margin: 0 0 50px 0;
            text-transform: uppercase;
            font-size: 6.5vw;
            line-height: 1;
            text-align: right;
            font-weight: 700;
            letter-spacing: -0.05em;
            color: #fff;

            @media only screen and (max-width: 1600px) {
                margin: 0 0 30px 0;
            }

            @media only screen and (max-width: 990px) {
                text-align: center;
                margin: 0 auto 40px;
                font-size: 40px;
            }

            span {
                display: block;
                font-size: 18px;
                letter-spacing: 0;
            }
        }

        p {
            margin: 0;
            font-size: 16px;
            color: #fff;

            @media only screen and (max-width: 1600px) {
                font-size: 15px;
            }
        }
    }
}

.read-more {
    clear: both;
    display: block;
    margin: 90px auto 0;
    text-align: center;
    text-align: center;
    width: 500px;
    height: 80px;
    line-height: 80px;
    box-shadow: inset 0 0 0 5px #fff;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    transition: .3s;

    @media only screen and (max-width: 1600px) {
        width: 350px;
        max-width: 95%;
        height: 60px;
        line-height: 60px;
    }

    @media only screen and (max-width: 990px) {
        margin: 50px auto 0;
    }

    &:hover {
        box-shadow: inset 0 0 0 3px rgba(#000, 1), inset 0 0 0 40px #8A2432;
        color: #000;
    }
}

// ----------------------------------- WELCOME SEC

#values-sec {
    padding: 100px 0;
    background: #9ea1a2;
    position: relative;
    border-top: 8px solid #fff;

    @media only screen and (max-width: 1500px) {
        padding: 80px 0;
    }

    @media only screen and (max-width: 990px) {
        padding: 50px 0;
    }

    &:before {
        @include before(calc(100% / 3), 100%);
        background: #cfd2d3;
        right: 0;
        top: 0;

        @media only screen and (max-width: 1600px) {
            @include before(calc(100% / 4), 100%);
        }

        @media only screen and (max-width: 990px) {
            display: none;
        }
    }

    .wrapper {
        width: 1250px;
        max-width: 95%;
    }

    .img-cont,
    .text-cont {
        display: inline-block;
        vertical-align: top;
    }

    .img-cont {
        width: 720px;

        @media only screen and (max-width: 1600px) {
            width: 520px;
        }

        @media only screen and (max-width: 990px) {
            float: none;
            display: block;
            max-width: 95%;
            margin: 0 auto;
        }

        img {
            width: 100%;
            filter: grayscale(100%);
        }

        p {
            background: #8A2432;
            padding: 50px;
            margin: 20px 0 0 0;
            color: #fff;
            text-align: right;
            font-size: 30px;
            line-height: 1.2;
            font-weight: 600;
            letter-spacing: -0.05em;

            @media only screen and (max-width: 1600px) {
                font-size: 25px;
                padding: 40px;
            }

            @media only screen and (max-width: 990px) {
                font-size: 20px;
                padding: 30px;
                text-align: center;
            }
        }
    }

    .text-cont {
        width: calc(100% - 720px - 50px);
        margin: 0 45px 0 0;

        @media only screen and (max-width: 1600px) {
            width: calc(100% - 520px - 50px);
        }

        @media only screen and (max-width: 990px) {
            display: block;
            width: 520px;
            max-width: 95%;
            margin: 0 auto 30px;
        }

        h2:not(li > h2) {
            color: #fff;
            margin: 0 0 25px 0;
            text-transform: uppercase;
            font-size: 6vw;
            line-height: 1;
            font-weight: 700;
            letter-spacing: -0.05em;

            @media only screen and (max-width: 990px) {
                text-align: center;
                margin: 0 auto 40px;
                font-size: 40px;
            }
        }

        p {
            margin: 0;
        }

        ul {

            @media only screen and (max-width: 990px) {
                text-align: center;
            }

            li {
                margin: 0 0 10px 0;
                padding: 0;
                background: none;

                @media only screen and (max-width: 990px) {
                    margin: 0 auto 20px;
                }

                * {
                    color: #fff;
                }

                h2 {
                    text-transform: uppercase;
                    font-size: 2.6vw;
                    margin: 0 0 10px 0;
                    font-weight: 600;

                    @media only screen and (max-width: 990px) {
                        font-size: 30px;
                    }
                }

                p {
                    padding: 0 0 0 30px;
                    line-height: 1.3;
                    margin: 0;
                    font-size: 16px;

                    @media only screen and (max-width: 1600px) {
                        font-size: 15px;
                    }

                    @media only screen and (max-width: 990px) {
                        padding: 0;
                    }
                }
            }
        }
    }
}







// -----------------------------------//
// EMPTY QUEERS
// -----------------------------------//

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1350px) {}

@media only screen and (max-width: 990px) {}

@media only screen and (max-width: 500px) {}

@media only screen and (max-width: 650px) and (orientation:landscape) {}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
all and (-ms-high-contrast: active),
screen\0,
screen\9 {}

// Edge fixes
@supports (-ms-ime-align:auto) {}